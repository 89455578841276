<template>
  <div>
    <div class="create-user-title background__blue_title">
      <p class="title-secondary fs-20 color__blue_main fw__bold">{{ title || $t('users_management.page_title.create') }}</p>
    </div>
    <div class="page-main-content mt-0" @keyup.enter="submit()">
      <div class="columns d-flex">
        <div class="column is-6">
          <div class="align-items-center">
            <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{ $t('users_management.labels.input_field.employee_id.label') }}
                <i :data-tooltip="$t('tooltip.required')"
                   class="required-note">*</i>
                </span>
            </div>
            <label>
              <input type="text"
                     :class="{'is-error': ((!vuelidate.user.employee_id.required || !vuelidate.user.employee_id.minLength || !vuelidate.user.employee_id.halfWidth
                   || !vuelidate.user.employee_id.maxLength ) && vuelidate.$dirty) || (error_server.employee_id && error_server.employee_id.length) }"
                     :placeholder="$t('users_management.labels.input_field.employee_id.placeholder')"
                     v-model.trim="user.employee_id"
                     class="input" :disabled="isUpdate">
            </label>
            <span class="error pt-1" v-if="error_server.employee_id && error_server.employee_id.length">
            {{ error_server.employee_id[0] }}
          </span>
            <template v-else-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.user.employee_id.required">
              {{ $t('users_management.messages.validation.employee_id_required') }}
            </span>
              <span class="error pt-1" v-else-if="!vuelidate.user.employee_id.minLength">
              {{ $t('users_management.messages.validation.employee_id_minlength') }}
            </span>
              <span class="error pt-1" v-else-if="!vuelidate.user.employee_id.halfWidth">
              {{ $t('users_management.messages.validation.employee_id_halfWidth') }}
            </span>
              <span class="error pt-1" v-else-if="!vuelidate.user.employee_id.maxLength">
              {{ $t('users_management.messages.validation.employee_id_maxlength') }}
            </span>
            </template>
          </div>
        </div>
        <div class="column is-6">
          <div class="align-items-center">
            <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                 {{ $t('users_management.labels.input_field.user_name.label') }}
                  <i :data-tooltip="$t('tooltip.required')"
                     class="required-note">*</i>
                </span>
            </div>
            <label>
              <input
                  :class="{'is-error': ((!vuelidate.user.name.required || !vuelidate.user.name.maxLength) && vuelidate.$dirty) || (error_server.name && error_server.name.length)}"
                  :placeholder=" $t('users_management.labels.input_field.user_name.placeholder')"
                  class="input"
                  type="text"
                  v-model.trim="user.name">
            </label>
            <span class="error pt-1" v-if="error_server.name && error_server.name.length">
            {{ error_server.name[0] }}
          </span>
            <template v-else-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.user.name.required && vuelidate.$dirty">
              {{ $t('users_management.messages.validation.username_required') }}
            </span>
              <span class="error pt-1" v-else-if="!vuelidate.user.name.maxLength && vuelidate.$dirty">
              {{ $t('users_management.messages.validation.username_maxlength') }}
            </span>
            </template>
          </div>
        </div>
      </div>
      <div class="columns d-flex">
        <div class="column is-6">
          <div class="align-items-center">
            <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                 {{ $t('users_management.labels.input_field.email.label') }}
                  <i :data-tooltip="$t('tooltip.required')"
                     class="required-note">*</i>
                </span>
            </div>
            <label>
              <input
                  :class="{'is-error': (!vuelidate.user.email.required || !vuelidate.user.email.validateEmail) && vuelidate.$dirty}"
                  :placeholder="$t('users_management.labels.input_field.email.placeholder')"
                  class="input"
                  type="text"
                  v-model.trim="user.email">
            </label>
            <span class="error pt-1" v-if="error_server.email && error_server.email.length">
            {{ error_server.email[0] }}
          </span>
            <template v-else-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.user.email.required">
            {{ $t('users_management.messages.validation.email_required') }}
            </span>
              <span class="error pt-1" v-else-if="!vuelidate.user.email.validateEmail">
            {{ $t('users_management.messages.validation.email_invalid') }}
            </span>
              <span class="error pt-1" v-else-if="!vuelidate.user.email.maxLength">
            {{ $t('users_management.messages.validation.email_maxlength') }}
            </span>
            </template>
          </div>
        </div>
        <div class="column is-6">
          <div class="align-items-center">
            <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{ $t('users_management.labels.input_field.branch.label') }}
                <i :data-tooltip="$t('tooltip.required')"
                   class="required-note">*</i>
                </span>
            </div>
            <dropdown-search :list="branches"
                             :is-invalid="!vuelidate.user.branch_id.required && vuelidate.$dirty"
                             display="name"
                             id-prefix="branches"
                             :placeholder="$t('users_management.labels.input_field.branch.placeholder')"
                             :selected="user.branch_id"
                             @change="getUserInformation($event, 'branch_id', 'dropdown-branch')">
            </dropdown-search>
            <span class="error pt-1" v-if="error_server.branch_id && error_server.branch_id.length">
            {{ error_server.branch_id[0] }}
          </span>
            <template v-else-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.user.branch_id.required">
           {{ $t('users_management.messages.validation.branch_required') }}
            </span>
            </template>
          </div>
        </div>
      </div>
      <div class="columns d-flex">
        <div class="column is-6">
          <div class="align-items-center">
            <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                 {{ $t('users_management.labels.input_field.division.label') }}
                  <i :data-tooltip="$t('tooltip.required')"
                     class="required-note">*</i>
                </span>
            </div>
            <dropdown-search :list="divisionsFiltered"
                             :is-invalid="!vuelidate.user.division_id.required && vuelidate.$dirty"
                             display="name"
                             id-prefix="divisions"
                             :placeholder="$t('users_management.labels.input_field.division.placeholder')"
                             :selected="user.division_id"
                             @change="getUserInformation($event, 'division_id', 'dropdown-division')">
            </dropdown-search>
            <span class="error pt-1" v-if="error_server.division_id && error_server.division_id.length">
            {{ error_server.division_id[0] }}
          </span>
            <template v-else-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.user.division_id.required">
            {{ $t('users_management.messages.validation.division_required') }}
            </span>
            </template>
          </div>
        </div>
        <div class="column is-6">
          <div class="align-items-center">
            <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{ $t('users_management.labels.input_field.office.label') }}
                <i :data-tooltip="$t('tooltip.required')"
                   class="required-note">*</i>
                </span>
            </div>
            <dropdown-search :list="officesFiltered"
                             :is-invalid="!vuelidate.user.office_id.required && vuelidate.$dirty"
                             display="name"
                             id-prefix="offices"
                             :placeholder="$t('users_management.labels.input_field.office.placeholder')"
                             :selected="user.office_id"
                             @change="getUserInformation($event, 'office_id', 'dropdown-office')">
            </dropdown-search>
            <span class="error pt-1" v-if="error_server.office_id && error_server.office_id.length">
            {{ error_server.office_id[0] }}
          </span>
            <template v-else-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.user.office_id.required">
            {{ $t('users_management.messages.validation.office_required') }}
            </span>
            </template>
          </div>
        </div>
      </div>
      <div class="columns d-flex">
        <div class="column is-6">
          <div class="align-items-center">
            <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                 {{ $t('users_management.labels.input_field.name.label') }}
                </span>
            </div>
            <label>
              <input :placeholder="$t('users_management.labels.input_field.name.placeholder')"
                     class="input"
                     :class="{'is-error': !vuelidate.user.full_name.maxLength && vuelidate.$dirty}"
                     type="text"
                     v-model.trim="user.full_name">
            </label>
            <template v-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.user.full_name.maxLength">
             {{ $t('users_management.messages.validation.name_maxlength') }}
            </span>
            </template>
          </div>
        </div>
        <div class="column is-6">
          <div class="align-items-center">
            <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{ $t('users_management.labels.input_field.kana_name.label') }}
                </span>
            </div>
            <label>
              <input :placeholder="$t('users_management.labels.input_field.kana_name.placeholder')"
                     class="input"
                     :class="{'is-error': (!vuelidate.user.katakana_name.katakana || !vuelidate.user.katakana_name.maxLength) && vuelidate.$dirty}"
                     type="text"
                     v-model.trim="user.katakana_name">
            </label>
            <template v-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.user.katakana_name.katakana">
             {{ $t('users_management.messages.validation.katakana_invalid') }}
            </span>
              <span class="error pt-1" v-if="!vuelidate.user.katakana_name.maxLength">
             {{ $t('users_management.messages.validation.katakana_maxlength') }}
            </span>
            </template>
          </div>
        </div>
      </div>
      <div class="columns d-flex p__bottom--20">
        <div class="column is-6">
          <div class="align-items-center">
            <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                 {{ $t('users_management.labels.change.position.label') }}
                </span>
              <i :data-tooltip="$t('tooltip.required')"
                 class="required-note">*</i>
            </div>
            <div class="dropdown w--100"
                 id="dropdown-position"
                 v-click-out="() => closeDropdown('dropdown-position')">
              <div class="dropdown-trigger w--100">
                <button class="button w--100 is-justify-content-flex-start"
                        aria-haspopup="true"
                        :class="{'is-error': !vuelidate.user.position_id.required && vuelidate.$dirty}"
                        @click="toggleDropdown('dropdown-position')"
                        style="border-radius: 5px !important; padding-left: 20px !important;"
                >
                <span class="fs-14"
                    :class="{
                  'placeholder-select': !dropDisplay(positions, user.position_id),
                  'color__blue_main': dropDisplay(positions, user.position_id)}
                    ">{{
                    dropDisplay(positions, user.position_id) || positionPlaceholder
                  }}</span>
                  <span class="icon is-small icon-angle-down"
                        :class="{'color-error': !vuelidate.user.position_id.required && vuelidate.$dirty}">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
                </button>
              </div>
              <div class="dropdown-menu w--100" role="menu">
                <div class="dropdown-content">
                  <a @click="getUserInformation(e, 'position_id', 'dropdown-position')"
                     v-for="(e, i) in positions"
                     :key="'position' + i"
                     :class="{'is-active': user.position_id === e.id}"
                     class="dropdown-item fs-14 color__blue_main">
                    {{ e.name }}
                  </a>
                </div>
              </div>
            </div>
            <span class="error pt-1" v-if="!vuelidate.user.position_id.required && vuelidate.$dirty">
            {{ $t('users_management.messages.validation.position_required') }}
          </span>
          </div>
        </div>
        <div class="column is-6" v-if="user.position_id">
          <div class="align-items-center">
            <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">
                  {{ $t('users_management.labels.change.role.label') }}
                </span>
              <i :data-tooltip="$t('tooltip.required')"
                 class="required-note">*</i>
            </div>
            <div class="dropdown w--100"
                 id="dropdown-role"
                 v-click-out="() => closeDropdown('dropdown-role')">
              <div class="dropdown-trigger w--100">
                <button class="button w--100 is-justify-content-flex-start"
                        aria-haspopup="true"
                        :disabled="isUpdate && userInfo && userUpdate && userInfo.id === userUpdate.id"
                        :class="{'is-error': (!vuelidate.user.role_id.required && vuelidate.$dirty)  || (error_server.role_id && error_server.role_id.length)}  "
                        @click="toggleDropdown('dropdown-role')"
                        style="border-radius: 5px !important; padding-left: 20px !important;"
                >
                <span class="fs-14"
                    :class="{
                  'placeholder-select': !dropDisplay(roles, user.role_id),
                  'color__blue_main': dropDisplay(positions, user.position_id)
                }">{{
                    dropDisplay(roles, user.role_id) || rolePlaceholder
                  }}</span>
                  <span class="icon is-small icon-angle-down"
                        :class="{'color-error': !vuelidate.user.role_id.required && vuelidate.$dirty}">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
                </button>
              </div>
              <div class="dropdown-menu w--100" role="menu">
                <div class="dropdown-content">
                  <template v-for="(e, i) in roles">
                    <a @click="getUserInformation(e, 'role_id', 'dropdown-role')"
                       :key="'role' + i"
                       v-if="e.id !== 1"
                       :class="{'is-active': user.role_id === e.id}"
                       class="dropdown-item">
                      {{ e.name }}
                    </a>
                  </template>
                </div>
              </div>
            </div>
            <span class="error pt-1" v-if="error_server.role_id && error_server.role_id.length">
            {{ error_server.role_id[0] }}
          </span>
            <template v-else-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.user.role_id.required">
              {{ $t('users_management.messages.validation.role_required') }}
            </span>
            </template>
          </div>
        </div>
      </div>
      <hr/>
      <div class="modal-card-foot is-justify-content-center background__white footer-create">
        <button class="button is-success close-modal btn-save background__blue_main"
                @click="submit()"> {{ buttonText || $t('users_management.buttons.save') }}
        </button>
        <button class="button btn-back background__grey close-modal"
                @click="backToList();">{{ $t('users_management.buttons.cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {UserService} from "@/services"
/*import {email, maxLength, required, minLength} from "vuelidate/lib/validators"*/
import {maxLength, required, minLength} from "vuelidate/lib/validators"
import {ACTION_SET_ACTIVE_SIDEBAR, ACTION_SET_PAGE_TITLE} from "@/stores/common/actions"
import User from '@/mixins/user'
import {katakana, halfWidth} from "@/helpers/utils"
import {emailRegexCheck} from "@/helpers/constant";

export default {
  name: "UserCreate",
  mixins: [User],
  data() {
    return {
      user: {
        employee_id: '',
        division_id: null,
        branch_id: null,
        office_id: null,
        position_id: null,
        role_id: null,
        name: '',
        email: '',
        full_name: '',
        katakana_name: '',
      },
      isUpdate: false,
      error_server: {},
      divisionsFiltered: [],
      officesFiltered: [],
      submitAvailable: true,
    }
  },
  validations: {
    user: {
      employee_id: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20),
        halfWidth: (val) => {
          return halfWidth(val)
        },
      },
      division_id: {
        required,
      },
      branch_id: {
        required,
      },
      office_id: {
        required,
      },
      position_id: {
        required,
      },
      role_id: {
        required,
      },
      email: {
        required,
        maxLength: maxLength(255),
        validateEmail : (value) => {
          return emailRegexCheck.test(String(value));
        }
      },
      name: {
        required,
        maxLength: maxLength(50),
      },
      full_name: {
        maxLength: maxLength(30),
      },
      katakana_name: {
        katakana: (val) => {
          return katakana(val)
        },
        maxLength: maxLength(50),
      },
      // password: {
      //     minLength: minLength(8),
      //     maxLength: maxLength(255),
      // },
      // password_confirmation: {
      //     minLength: minLength(8),
      //     maxLength: maxLength(255),
      //     sameAs: sameAs(function () {
      //         return this.user.password
      //     })
      // },
    },
  },
  props: {
    userUpdate: {
      type: Object,
      default: () => {
      },
    },
    title: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    }

  },
  watch: {
    userUpdate(val) {
      if (val) {
        this.isUpdate = true
        this.$nextTick(() => {
          this.user = {...val}
          if (this.user.office) {
            this.divisionsFiltered = []
            this.divisions.map((e) => {
              if (e.branch_id === this.user.office.division.branch.id) {
                this.divisionsFiltered = [...this.divisionsFiltered, ...[e]]
              }
            })
            this.officesFiltered = []
            this.offices.map((e) => {
              if (e.division_id === this.user.office.division.id) {
                this.officesFiltered = [...this.officesFiltered, ...[e]]
              }
            })
            this.$set(this.user, 'branch_id', this.user.office.division.branch.id)
            this.$set(this.user, 'division_id', this.user.office.division.id)
            this.$set(this.user, 'office_id', this.user.office.id)
          }
          this.$set(this.user, 'position_id', this.user.position ? this.user.position.id : null)
          this.$set(this.user, 'role_id', this.user.role ? this.user.role.id : null)
        })
      } else {
        this.isUpdate = false
      }
    },
    divisions(val) {
      this.divisionsFiltered = []
      val.map((e) => {
        if (e.branch_id === this.user.branch_id) {
          this.divisionsFiltered = [...this.divisionsFiltered, ...[e]]
        }
      })
    },
    offices(val) {
      val.map((e) => {
        if (e.division_id === this.user.division_id) {
          this.officesFiltered = [...this.officesFiltered, ...[e]]
        }
      })
    }
    // 'user.branch_id': function (newVal, oldVal) {
    //   if (newVal && newVal !== oldVal) {
    //     this.$set(this.user, 'division_id', null)
    //     this.$set(this.user, 'office_id', null)
    //     this.divisionsFiltered = []
    //     this.officesFiltered = []
    //     this.divisions.map((e) => {
    //       if (e.branch_id === newVal) {
    //         this.divisionsFiltered = [...this.divisionsFiltered, ...[e]]
    //       }
    //     })
    //   }
    // },
    // 'user.division_id': function (newVal, oldVal) {
    //   if (newVal && newVal !== oldVal) {
    //     this.$set(this.user, 'office_id', null)
    //     this.officesFiltered = []
    //     this.offices.map((e) => {
    //       if (e.division_id === newVal) {
    //         this.officesFiltered = [...this.officesFiltered, ...[e]]
    //       }
    //     })
    //   }
    // },
  },
  methods: {
    backToList() {
      this.$router.push({name: 'Users'}, () => {})
    },
    getUserInformation(value, key, el = '') {
      if (this.user[key] !== value.id) {
        this.user[key] = value.id
        switch (key) {
          case 'branch_id': {
            this.$set(this.user, 'division_id', null)
            this.$set(this.user, 'office_id', null)
            this.divisionsFiltered = []
            this.officesFiltered = []
            this.divisions.map((e) => {
              if (e.branch_id === value.id) {
                this.divisionsFiltered = [...this.divisionsFiltered, ...[e]]
              }
            })
            break
          }
          case 'division_id': {
            this.$set(this.user, 'office_id', null)
            this.officesFiltered = []
            this.offices.map((e) => {
              if (e.division_id === value.id) {
                this.officesFiltered = [...this.officesFiltered, ...[e]]
              }
            })
            break
          }
          case 'position_id': {
            if (!this.isUpdate) {
              this.user.role_id = value.role ? value.role.id : 2
            }
            break
          }
          default: {
            break
          }
        }
      }
      // if (key === 'position_id') {
      //   this.user.role_id = 2
      // }
      el && this.closeDropdown(el)
    },
    checkValidate() {
      if (this.isUpdate) {
        return !this.vuelidate.user.role_id.$invalid
            && !this.vuelidate.user.branch_id.$invalid
            && !this.vuelidate.user.division_id.$invalid
            && !this.vuelidate.user.office_id.$invalid
            && !this.vuelidate.user.position_id.$invalid
            && !this.vuelidate.user.role_id.$invalid
            && !this.vuelidate.user.email.$invalid
            && !this.vuelidate.user.name.$invalid
            && !this.vuelidate.user.full_name.$invalid
            && !this.vuelidate.user.katakana_name.$invalid
            && !this.vuelidate.user.email.$invalid
      } else {
        return !this.vuelidate.user.employee_id.$invalid
            && !this.vuelidate.user.branch_id.$invalid
            && !this.vuelidate.user.division_id.$invalid
            && !this.vuelidate.user.office_id.$invalid
            && !this.vuelidate.user.position_id.$invalid
            && !this.vuelidate.user.role_id.$invalid
            && !this.vuelidate.user.email.$invalid
            && !this.vuelidate.user.name.$invalid
            && !this.vuelidate.user.full_name.$invalid
            && !this.vuelidate.user.katakana_name.$invalid
            && !this.vuelidate.user.email.$invalid
      }
    },
    submit() {
      this.error_server = {};
      this.vuelidate.$touch()
      if (this.checkValidate() && this.submitAvailable) {
        console.log(2123)
        delete this.user.office
        delete this.user.position
        delete this.user.role
        delete this.user.created_at
        delete this.user.updated_at
        this.submitAvailable = false
        if (this.isUpdate) {
          UserService.update(this.user.id, this.user)
              .then(() => {
                this.submitAvailable = true
                this.$toast.success(this.$t('users_management.messages.update_success'))
                this.$router.push({name: 'Users'}, () => {})
                this.closeModal('modal-change-user')
              })
              .catch((err) => {
                this.submitAvailable = true
                this.error_server = err.data.errors
                // this.$toast.error(err.data.message)
              })
        } else {
          UserService.create(this.user)
              .then((res) => {
                if (res.status === 205) {
                  this.$popup(
                      this.$t('users_management.popup.restore_user.title'),
                      this.$t('users_management.popup.restore_user.content'),
                      {
                        okText: this.$t('users_management.popup.restore_user.ok'),
                        cancelText: this.$t('users_management.popup.restore_user.cancel'),
                      },
                      {
                        onOk: (d) => {
                          UserService.restore(this.user)
                              .then(() => {
                                this.submitAvailable = true
                                this.$toast.success(this.$t('users_management.messages.restore_success'))
                                this.$router.push({name: 'Users'}, () => {})
                                this.closeModal('modal-change-user')
                              })
                              .catch((err) => {
                                console.log(err);
                                this.submitAvailable = true
                                // this.$toast.error(err.data.message);
                              })
                          d.closeDialog()
                        },
                        onCancel: (d) => {
                          this.submitAvailable = true
                          d.closeDialog()
                        },
                        onClickOut: (d) => {
                          this.submitAvailable = true
                          d.closeDialog()
                        }
                      }
                  )
                } else {
                  this.submitAvailable = true
                  this.$toast.success(this.$t('users_management.messages.create_success'))
                  this.$router.push({name: 'Users'}, () => {})
                  this.closeModal('modal-change-user')
                }
              })
              .catch((err) => {
                this.submitAvailable = true
                this.error_server = err.data.errors
              })
        }
      }
    },
  },
  created() {
    this.getData('offices')
    this.getData('positions')
    this.getData('divisions')
    this.getData('branches')
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'users')
  },
  mounted() {
    if (this.title) {
      this.$store.dispatch(ACTION_SET_PAGE_TITLE, this.title)
    } else {
      this.$store.dispatch(ACTION_SET_PAGE_TITLE, this.$t('users_management.page_title.create'))
    }
  },
}
</script>

<style lang="scss" scoped>
.page-main-content {
  border-radius: 0 0 10px 10px;
}
.dropdown-content {
  max-height: 120px;
  overflow-y: scroll;
}

.icon-angle-down {
  position: absolute;
  right: 12px;
  top: 8px;
  color: #808080;
}

.placeholder-select {
  color: #c2c2c2;
}

button {
  &.btn-save {
    border-radius: 18px !important;
    height: 35px !important;
    width: 140px !important;
    border:  none !important;
    font-size: 16px !important;
    margin-top: 10px;
  }
  &.btn-back {
    border-radius: 18px !important;
    height: 35px !important;
    width: 140px !important;
    border:  none !important;
    font-size: 16px !important;
    background-color: #E4E4E4 !important;
    color: #979797 !important;
    margin-top: 10px;
  }
}

.page-main-content .column {
  padding: 0;
}
.column:nth-child(1) {
  padding-right: 10px !important;
}
.column:nth-child(2) {
  padding-left: 10px !important;
}
.button {
  &:hover {
    border-color: $blue_button;
  }
  &:focus {
    border-color: $blue_button;
    box-shadow: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) {
  .create-user-title {
    padding: 10px 20px !important;
  }
  .page-main-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.create-user-title {
  padding: 10px 60px;
  border-radius: 10px 10px 0 0;
}

.page-main-content {
  padding-left: 60px;
  padding-right: 60px;
  .columns {
    margin: 0;
    margin-bottom: 16px;
  }
}

.footer-create {
  margin-top: 0px;
  border-top: none !important;
}

.placeholder-select {
  font-size: 14px !important;
}
.input {
  height: 40px;
  color: $blue_button;
}
.input::placeholder {
  font-size: 14px;
}
.input:valid {
  font-size: 14px !important;
}
.input:hover {
  border-color: $blue_button !important;
  box-shadow: none !important;
}

.input:focus {
  border-color: $blue_button !important;
  box-shadow: none !important;
}

.input:disabled {
  font-size: 12px;
}

</style>
