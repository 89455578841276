import ApiService from '@/services/ApiService';

const MixinService = {
    offices(limit_data = 0) {
        if (limit_data) {
            return ApiService.get(`offices?limit_data=${limit_data}`)
        }
        return ApiService.get('offices')
    },
    positions(limit_data = 0) {
        if (limit_data) {
            return ApiService.get(`positions?limit_data=${limit_data}`)
        }
        return ApiService.get('positions')
    },
    roles() {
        return ApiService.get('roles')
    },
    divisions(limit_data = 0) {
        if (limit_data) {
            return ApiService.get(`divisions?limit_data=${limit_data}`)
        }
        return ApiService.get('divisions')
    },
    branches(limit_data = 0) {
        if (limit_data) {
            return ApiService.get(`branches?limit_data=${limit_data}`)
        }
        return ApiService.get('branches')
    }
}

export default MixinService